$('#events').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});


//medallions
$('.hover').mouseleave(
    function () {
        $(this).removeClass('hover');
    }
);

$('.left-nav-link').on('click', function(e){
    e.preventDefault();
    $.each($('.left-nav-link'), function(){
       if ($(this).hasClass('active')) {
           $(this).removeClass('active');
       }
    });
    $(this).addClass('active');


    $.each($('.data-block'), function(){
       $(this).css('display', 'none');
    });
    $showBlock = $(this).data('toggle');
    $('#'+$showBlock).css('display', 'block');
});

$(window).scroll(function(){
    $top = $('html').scrollTop();
    $el = 500;
    if ($top >= $el) {
        $('#tmma').addClass('animated fadeInUp');
        console.log('i fired');
    }
});